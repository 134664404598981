"use strict";
$(document).ready(function() {
	"use strict";
	var $address;
	var $city;
	var $zip;
	var $lat;
	var $lng;
	$("#gmaps").one("focus", function() {
		var $form = $(this).closest("form");
		$address = $form.find("input[name='address']");
		$city = $form.find("input[name='city']");
		$zip = $form.find("input[name='zip']");
		$lat = $form.find("input[name='lat']");
		$lng = $form.find("input[name='lng']");
		init_maps.call(this).fail(function() {
			$address.prop("readonly", false);
			$city.prop("readonly", false);
			$zip.prop("readonly", false);
			$("#gmaps, #gmaps + .form-text").remove();
		});
	});

	function get_api_key() {
		return $.getJSON("/action/gapi");
	}

	function validate_place(results, $form) {
		var position = {};
		if (typeof results === "object" && typeof results.address_components === "object") {
			for (var i = 0; i < results.address_components.length; i++) {
				for (var j = 0; j < results.address_components[i].types.length; j++) {
					switch (results.address_components[i].types[j]) {
						case "postal_code":
							position.postal_code = results.address_components[i].long_name.replace(/ /g, "");
							break;
						case "route":
							position.route = results.address_components[i].long_name;
							break;
						case "street_number":
							position.street_number = results.address_components[i].long_name;
							break;
						case "locality":
							position.locality = results.address_components[i].long_name;
						case "administrative_area_level_5":
							if (position.locality === undefined) {
								position.locality = results.address_components[i].long_name;
							}
							break;
					}
				}
			}
			if (position.route === undefined) position.route = '';
			if (position.locality === undefined) position.locality = '';
			if (position.street_number === undefined) position.street_number = '';
			if (position.postal_code === undefined) position.postal_code = '';
			$address.val((position.route + " " + position.street_number).trim()).prop("readonly", false);
			$city.val(position.locality).prop("readonly", false);
			$zip.val(position.postal_code).prop("readonly", false);
			if (typeof results.geometry === "object") {
				$lat.val(results.geometry.location.lat());
				$lng.val(results.geometry.location.lng());
			}
		}
	}

	function init_maps(e) {
		var deferred = $.Deferred();
		var elem = document.getElementById("gmaps");
		var $elem = $(elem);
		if (typeof window.google === "object" && typeof window.google.maps === "object") {
			return deferred.resolveWith(elem).promise();
		}
		get_api_key().done(function(json) {
			if (!json.key.length) {
				deferred.rejectWith(elem);
				return;
			}
			$elem.attr('placeholder', 'Παρακαλώ περιμένετε...');
			$elem.prop('disabled', true);
			$.getScript("https://maps.googleapis.com/maps/api/js?key=" + json.key + "&language=el&libraries=places").done(function(script, textStatus) {
				if (textStatus === "success") {
					var ac = new google.maps.places.Autocomplete(elem, {
						types: ['geocode'],
						componentRestrictions: {
							'country': 'gr'
						},
						strictBounds: true,
						fields: ['address_components', 'geometry']
					});
					google.maps.event.addListener(ac, 'place_changed', function() {
						validate_place(ac.getPlace(), $elem.closest("form"));
					});
					deferred.resolveWith(elem);
				} else {
					deferred.rejectWith(elem);
				}
			}).fail(function() {
				deferred.rejectWith(elem);
			}).always(function() {
				$elem.attr('placeholder', '');
				$elem.prop('disabled', false);
				$elem.focus();
			});
		}).fail(function() {
			deferred.rejectWith(elem);
		});
		return deferred.promise();
	}
});