"use strict";
$(document).ready(function() {
	"use strict";
	switch (view()) {
		case "apps.contacts.lists":
			break;
		default:
			return;
	}
	$("#ul-contact-list").DataTable({
		paging: false,
		info: false,
		searching: false
	});
});