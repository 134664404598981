"use strict";
$(document).ready(function() {
	switch (view()) {
		case "uiKits.carousel":
		case "uiKits.collapsible":
			break;
		default:
			return;
	}
	$('#carouselExampleInterval').carousel({
		interval: 2000
	});
	$('#carouselExamplePause').carousel({
		pause: "hover"
	});
	$('#carouselExampleWrap').carousel({
		wrap: false
	});
	$('#carouselExampleKeyboard').carousel({
		keyboard: true
	});
});