"use strict";
if (view() === "apps.task-manager") {
	var click = document.getElementById('custom-toggle');
	var click2 = document.getElementById('custom-toggle2');
	var click3 = document.getElementById('custom-toggle3');
	var click4 = document.getElementById('custom-toggle4');
	var click5 = document.getElementById('custom-toggle5');
	var click6 = document.getElementById('custom-toggle6');

	function customToggle() {
		click.classList.toggle('active');
	}

	function customToggle2() {
		click2.classList.toggle('active');
	}

	function customToggle3() {
		click3.classList.toggle('active');
	}

	function customToggle4() {
		click4.classList.toggle('active');
	}

	function customToggle5() {
		click5.classList.toggle('active');
	}

	function customToggle6() {
		click6.classList.toggle('active');
	}
}