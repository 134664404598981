"use strict";
$(document).ready(function() {
	"use strict";
	switch (layout()) {
		case "vertical":
			break;
		default:
			return;
	}
	$(function() {
		$('#menu').metisMenu();
	});
});