"use strict";
$(document).ready(function() {
	"use strict";
	switch (view()) {
		case "apps.contacts.contact-list-table":
			break;
		default:
			return;
	}
	var $appAdminWrap = $(".app-admin-wrap");
	var $mobileIcon = $(".ul-contact-mobile-icon");
	var $childOpenMenu = $(".ul-contact-left-side");
	var $childCloseMenu = $(".contact-close-mobile-icon");
	$mobileIcon.on("click", function() {
		$childOpenMenu.addClass('contact-open');
	});
	$childCloseMenu.on("click", function() {
		$childOpenMenu.removeClass('contact-open');
	});
});