"use strict";
$(document).ready(function() {
	switch (view()) {
		case "forms.smartWizard":
			break;
		default:
			return;
	}
	$("#smartwizard").on("showStep", function(e, anchorObject, stepNumber, stepDirection, stepPosition) {
		if (stepPosition === 'first') {
			$("#prev-btn").addClass('disabled');
		} else if (stepPosition === 'final') {
			$("#next-btn").addClass('disabled');
		} else {
			$("#prev-btn").removeClass('disabled');
			$("#next-btn").removeClass('disabled');
		}
	});
	var btnFinish = $('<button></button>').text('Finish').addClass('btn btn-info').on('click', function() {
		void 0;
	});
	var btnCancel = $('<button></button>').text('Cancel').addClass('btn btn-danger').on('click', function() {
		$('#smartwizard').smartWizard("reset");
	});
	$('#smartwizard').smartWizard({
		selected: 0,
		theme: 'default',
		transitionEffect: 'fade',
		showStepURLhash: true,
		toolbarSettings: {
			toolbarPosition: 'both',
			toolbarButtonPosition: 'end',
			toolbarExtraButtons: [btnFinish, btnCancel]
		}
	});
	$("#reset-btn").on("click", function() {
		$('#smartwizard').smartWizard("reset");
		return true;
	});
	$("#prev-btn").on("click", function() {
		$('#smartwizard').smartWizard("prev");
		return true;
	});
	$("#next-btn").on("click", function() {
		$('#smartwizard').smartWizard("next");
		return true;
	});
	$("#theme_selector").on("change", function() {
		$('#smartwizard').smartWizard("theme", $(this).val());
		return true;
	});
	$("#theme_selector").change();
});