"use strict";
$(document).ready(function() {
	switch (view()) {
		case "apps.contacts.contact-list-table":
		case "datatables.basic-tables":
			break;
		default:
			return;
	}
	return;
	if ($('#contact_list_table').length) $('#contact_list_table').DataTable({
		searching: false
	});
	$('#zero_configuration_table').DataTable();
	$('#feature_disable_table').DataTable({
		"paging": false,
		"ordering": false,
		"info": false
	});
	$('#deafult_ordering_table').DataTable({
		"order": [
			[3, "desc"]
		]
	});
	$('#multicolumn_ordering_table').DataTable({
		columnDefs: [{
			targets: [0],
			orderData: [0, 1]
		}, {
			targets: [1],
			orderData: [1, 0]
		}, {
			targets: [4],
			orderData: [4, 0]
		}]
	});
	$('#hidden_column_table').DataTable({
		responsive: true,
		"columnDefs": [{
			"targets": [2],
			"visible": false,
			"searchable": false
		}, {
			"targets": [3],
			"visible": false
		}]
	});
	$('#complex_header_table').DataTable();
	$('#dom_positioning_table').DataTable({
		"dom": '<"top"i>rt<"bottom"flp><"clear">'
	});
	$('#alternative_pagination_table').DataTable({
		"pagingType": "full_numbers"
	});
	$('#scroll_vertical_table').DataTable({
		"scrollY": "200px",
		"scrollCollapse": true,
		"paging": false
	});
	$('#scroll_horizontal_table').DataTable({
		"scrollX": true
	});
	$('#scroll_vertical_dynamic_height_table').DataTable({
		scrollY: '50vh',
		scrollCollapse: true,
		paging: false
	});
	$('#scroll_horizontal_vertical_table').DataTable({
		"scrollY": 200,
		"scrollX": true
	});
	$('#comma_decimal_table').DataTable({
		"language": {
			"decimal": ",",
			"thousands": "."
		}
	});
	$('#language_option_table').DataTable({
		"language": {
			"lengthMenu": "Display _MENU_ records per page",
			"zeroRecords": "Nothing found - sorry",
			"info": "Showing page _PAGE_ of _PAGES_",
			"infoEmpty": "No records available",
			"infoFiltered": "(filtered from _MAX_ total records)"
		}
	});
});