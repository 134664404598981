"use strict";
$(document).ready(function() {
	switch (view()) {
		case "apps.invoice":
			break;
		default:
			return;
	}
	$('#order-datepicker').pickadate();
	$('.print-invoice').on('click', function() {
		window.print();
	});
});