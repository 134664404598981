"use strict";
$(document).ready(function() {
	return;
	if (!document.getElementById("full-editor")) return;
	var quill = new Quill('#full-editor', {
		modules: {
			syntax: !0,
			toolbar: [
				[{
					font: []
				}, {
					size: []
				}],
				["bold", "italic", "underline", "strike"],
				[{
					color: []
				}, {
					background: []
				}],
				[],
				[{
					header: "1"
				}, {
					header: "2"
				}],
				[{
					list: "ordered"
				}, {
					list: "bullet"
				}, {
					indent: "-1"
				}, {
					indent: "+1"
				}],
				["direction", {
					align: []
				}],
				["link", "image", "video"],
				["clean"]
			]
		},
		theme: 'snow'
	});
});