"use strict";
$(document).ready(function() {
	switch (view()) {
		case "extraKits.upload":
			break;
		default:
			return;
	}
	Dropzone.options.singleFileUpload = {
		paramName: "file",
		maxFiles: 1,
		init: function init() {
			this.on("maxfilesexceeded", function(file) {
				this.removeAllFiles();
				this.addFile(file);
			});
		}
	};
	Dropzone.options.multipleFileUpload = {
		paramName: "file",
		maxFilesize: 1,
		clickable: true
	};
	new Dropzone(document.body, {
		url: "#",
		previewsContainer: "#button-select-upload",
		clickable: "#button-select"
	});
	Dropzone.options.fileLimitUpload = {
		paramName: "file",
		maxFilesize: 1,
		maxFiles: 4,
		maxThumbnailFilesize: 1
	};
	Dropzone.options.fileAcceptedUpload = {
		paramName: "file",
		maxFilesize: 1,
		acceptedFiles: 'image/*'
	};
	Dropzone.options.fileRemoveThumbnailUpload = {
		paramName: "file",
		maxFilesize: 1,
		addRemoveLinks: true,
		dictRemoveFile: " Trash"
	};
});