"use strict";
$(document).ready(function() {
	'use strict';
	switch (view()) {
		case "forms.form-validation":
		case "patients.patient-create":
		case "widgets.app":
			break;
		default:
			return;
	}
	var forms = document.getElementsByClassName('needs-validation');
	var validation = Array.prototype.filter.call(forms, function(form) {
		form.addEventListener('submit', function(event) {
			if (form.checkValidity() === false) {
				event.preventDefault();
				event.stopPropagation();
			}
			form.classList.add('was-validated');
		}, false);
	});
});