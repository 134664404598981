"use strict";
$(document).ready(function() {
	switch (layout()) {
		case "horizontal":
			break;
		default:
			return;
	}
	if ($(".horizontal-bar-wrap").length === 0) {
		return;
	}
	$('.menu-toggle').on('click', function(e) {
		e.preventDefault();
		$('.header-topnav').toggleClass('open');
	});
});