"use strict";
$(document).ready(function() {
	switch (view()) {
		case "patient.create":
		case "patient.profile":
			break;
		default:
			return;
	}
	var quill = new Quill('#notes', {
		modules: {
			syntax: !0,
			toolbar: [
				[{
					font: []
				}, {
					size: []
				}],
				["bold", "italic", "underline", "strike"],
				[{
					color: []
				}, {
					background: []
				}],
				[],
				[{
					header: "1"
				}, {
					header: "2"
				}],
				[{
					list: "ordered"
				}, {
					list: "bullet"
				}, {
					indent: "-1"
				}, {
					indent: "+1"
				}],
				["direction", {
					align: []
				}],
				["link", "video"],
				["clean"]
			]
		},
		theme: 'snow'
	});
	var Delta = Quill.import('delta');
	var comments = $("#quill_delta").html();
	var default_delta = {
		"ops": [{
			"insert": "Ιστορικό"
		}, {
			"insert": "\n",
			"attributes": {
				"header": 2
			}
		}, {
			"insert": "\n"
		}, {
			"insert": "\n",
			"attributes": {
				"list": "ordered"
			}
		}, {
			"insert": "\nΦάρμακα"
		}, {
			"insert": "\n",
			"attributes": {
				"header": 2
			}
		}, {
			"insert": "\n"
		}, {
			"insert": "\n",
			"attributes": {
				"list": "ordered"
			}
		}, {
			"insert": "\n"
		}, {
			"insert": "Περισσότερες σημειώσεις..."
		}]
	};
	quill.setContents(new Delta(comments == null ? default_delta : JSON.parse(comments)));
	$("#patient").on("submit", function(event) {
		event.preventDefault();
		event.stopPropagation();
		var $form = $(this);
		$form.find("input[name='comments']").val(JSON.stringify(quill.getContents()));
		$.ajax({
			url: $form.attr("action"),
			method: $form.attr("method"),
			data: $form.serialize(),
			dataType: "json"
		}).then(function(patient) {
			$form.removeClass("was-validated");
			if ($form.attr("method").toUpperCase() === "PUT") {
				toastr.success("Η εγγραφή ενημερώθηκε με επιτυχία!", "Ενημερώθηκε!");
				return;
			}
			$form[0].reset();
			quill.setContents(new Delta(default_delta));
			swal({
				type: 'success',
				title: 'Επιτυχία!',
				text: 'Η εγγραφή ενημερώθηκε',
				buttonsStyling: false,
				confirmButtonClass: 'btn btn-lg btn-primary mb-2 mr-3',
				cancelButtonClass: 'btn btn-lg btn-outline-primary mb-2 mr-3 border-0',
				showCancelButton: true,
				confirmButtonText: 'Καρτέλα πελάτη',
				cancelButtonText: 'Προσθήκη νέου πελάτη'
			}).then(result => {
				window.location.href = patient.url;
			}, result => {
				$('html, body').animate({
					scrollTop: $("#name").offset().top - 100
				}, 300, function() {
					$("#name").focus();
				});
			});
		}, function(jqxhr) {
			var errors = jqxhr.responseJSON.errors;
			$("#gmaps")[0].setCustomValidity("");
			$("#gmaps").closest(".form-group").find(".invalid-tooltip").text("");
			var first = null;
			var $fields = $form.find("input, select").each(function(i, field) {
				var $field = $(field);
				var name = $field.attr("name");
				if (name == null) return;
				var $validate_tooltip = $field.closest(".form-group").find(".invalid-tooltip");
				if ($validate_tooltip != null) $validate_tooltip.text("");
				field.setCustomValidity("");
				if (errors[name] != null && errors[name].length) {
					if ($validate_tooltip != null) $validate_tooltip.text(errors[name][0]);
					field.setCustomValidity(errors[name][0]);
					if (first == null) first = field;
					switch (name) {
						case "address":
						case "city":
						case "zip":
							$("#gmaps")[0].setCustomValidity("Προέκυψε σφάλμα με την επικύρωση διεύθυνσης!");
							$("#gmaps").closest(".form-group").find(".invalid-tooltip").text("Προέκυψε σφάλμα με την επικύρωση διεύθυνσης!");
							break;
					}
				}
			});
			$form.addClass("was-validated");
			toastr.error("Προέκυψε σφάλμα με την επικύρωση των πεδίων ασθενή!", "Σφάλμα!");
			if (first != null) {
				$('html, body').animate({
					scrollTop: $(first).offset().top - 100
				}, 300, function() {
					$(first).focus();
				});
			}
		});
	});
	var fathersname = new inputSuggest({
		selector: "#fathersname",
		title: "Αναζήτηση στους ασθενείς...",
		data: {
			target: "fathersname",
			type: ["name"]
		},
		callback: function callback(index, data) {
			return "\n                <a class=\"dropdown-item list-group-item list-group-item-action flex-column align-items-start border-0 py-2\" href=\"#\">\n                    <div class=\"d-flex w-100 justify-content-between\">\n                         <h5 class=\"mb-1\">".concat(data.name, "</h5>\n                     </div>\n                 </a>\n            ");
		},
		onselect: function onselect(index, data, event) {
			$(this).val(data.name).focus();
			event.preventDefault();
		},
		oninput: function oninput(current_value, data) {
			var $input = $(this);
			if (data.name_gl != null && current_value.length) {
				if (new RegExp("\\b".concat(current_value, "\\b"), 'gi').test(data.name_gl)) {
					$input.val($input.val().replace(current_value, data.name));
				}
			} else if (current_value.length) {
				if (data.name.localeCompare(current_value, 'el-GR', {
						sensitivity: 'base'
					}) === 0) {
					$input.val($input.val().replace(current_value, data.name));
				}
			}
		}
	});
	var introduced = new inputSuggest({
		selector: "#introduce",
		title: "Αναζήτηση στους ασθενείς...",
		data: {
			target: "fullname",
			type: ["name", "surname", "date_birth", "city", "fathersname"]
		},
		callback: function callback(index, data) {
			return "\n                <a class=\"dropdown-item list-group-item list-group-item-action flex-column align-items-start border-0 py-2\" href=\"#\">\n                    <div class=\"d-flex w-100 justify-content-between\">\n                        <h5 class=\"mb-1\">".concat(data.surname, " ").concat(data.name).concat(data.fathersname != null ? " <small class=\"text-muted font-italic\"> \u03C4\u03BF\u03C5 ".concat(data.fathersname, "</small>") : "", "</h5><small class=\"ml-3\">").concat(moment().diff(data.date_birth, 'years'), " \u03B5\u03C4\u03CE\u03BD</small>\n                    </div>\n                    <p class=\"mb-1\"></p><small>").concat(data.city, "</small>\n                </a>\n            ");
		},
		onselect: function onselect(index, data, event) {
			$(this).val(data.name + " " + data.surname).focus();
			event.preventDefault();
		}
	});
	var firstname = new inputSuggest({
		selector: "#name",
		title: "Αναζήτηση στους ασθενείς...",
		data: {
			target: "firstname",
			type: ["name", "gender"]
		},
		callback: function callback(index, data) {
			return "\n                <a class=\"dropdown-item list-group-item list-group-item-action flex-column align-items-start border-0 py-2\" href=\"#\">\n                    <div class=\"d-flex w-100 justify-content-between\">\n                         <h5 class=\"mb-1\">".concat(data.name, "</h5>\n                     </div>\n                 </a>\n            ");
		},
		onselect: function onselect(index, data, event) {
			$(this).val(data.name).focus();
			$("#gender").val(data.gender);
			event.preventDefault();
		},
		oninput: function oninput(current_value, data) {
			var $input = $(this);
			if (data.name_gl != null && current_value.length) {
				if (new RegExp("\\b".concat(current_value, "\\b"), 'gi').test(data.name_gl)) {
					$input.val($input.val().replace(current_value, data.name));
					$("#gender").val(data.gender);
				}
			} else if (current_value.length) {
				if (data.name.localeCompare(current_value, 'el-GR', {
						sensitivity: 'base'
					}) === 0) {
					$input.val($input.val().replace(current_value, data.name));
					$("#gender").val(data.gender);
				}
			}
		}
	});
	$("#comments").on("submit", function(event) {
		event.preventDefault();
		event.stopPropagation();
		var $form = $(this);
		$form.find("input[name='comments']").val(JSON.stringify(quill.getContents()));
		$.ajax({
			url: $form.attr("action"),
			method: $form.attr("method"),
			data: $form.serialize(),
			dataType: "json"
		}).then(function() {
			$form.removeClass("was-validated");
			if ($form.attr("method").toUpperCase() === "PUT") {
				toastr.success("Η εγγραφή ενημερώθηκε με επιτυχία!", "Ενημερώθηκε!");
				return;
			}
		}, function(jqxhr) {
			toastr.error("Προέκυψε σφάλμα με την επικύρωση των πεδίων ασθενή!", "Σφάλμα!");
		});
	});
	$("#appointment").on("submit", function(event) {
		event.preventDefault();
		event.stopPropagation();
		var $form = $(this);
		$.ajax({
			url: $form.attr("action"),
			method: $form.attr("method"),
			data: $form.serialize(),
			dataType: "json"
		}).then(function() {
			$form.removeClass("was-validated");
			if ($form.attr("method").toUpperCase() === "PUT") {
				toastr.success("Η εγγραφή ενημερώθηκε με επιτυχία!", "Ενημερώθηκε!");
				return;
			}
			$form[0].reset();
			swal({
				type: 'success',
				title: 'Επιτυχία!',
				text: 'Η εγγραφή ενημερώθηκε',
				showConfirmButton: false,
				showCancelButton: false
			});
			setTimeout(function() {
				window.location.reload();
			}, 1500);
		}, function(jqxhr) {
			var errors = jqxhr.responseJSON.errors;
			var first = null;
			var $fields = $form.find("input, select, textarea").each(function(i, field) {
				var $field = $(field);
				var name = $field.attr("name");
				if (name == null) return;
				var $validate_tooltip = $field.closest(".form-group").find(".invalid-tooltip");
				if ($validate_tooltip != null) $validate_tooltip.text("");
				field.setCustomValidity("");
				if (errors[name] != null && errors[name].length) {
					if ($validate_tooltip != null) $validate_tooltip.text(errors[name][0]);
					field.setCustomValidity(errors[name][0]);
					if (first == null) first = field;
				}
			});
			$form.addClass("was-validated");
			toastr.error("Προέκυψε σφάλμα με την επικύρωση των πεδίων ασθενή!", "Σφάλμα!");
			if (first != null) {
				$('html, body').animate({
					scrollTop: $(first).offset().top - 100
				}, 300, function() {
					$(first).focus();
				});
			}
		});
	});
});