"use strict";
$(document).ready(function() {
	switch (view()) {
		case "forms.tagInput":
			break;
		default:
			return;
	}
	$(".tagBox").tagging();
	$(".forbidden-words").tagging({
		"forbidden-words": ["shit", "bloody"]
	});
});