'use strict';
if (view() === "widgets.list") {
	var options6 = {
		chart: {
			type: 'bar',
			width: '20%',
			height: 30,
			sparkline: {
				enabled: true
			}
		},
		plotOptions: {
			bar: {
				columnWidth: '40%'
			}
		},
		colors: ['#c43235'],
		series: [{
			data: [25, 15, 35, 35, 55, -75, -100, 75, 55, 35, 25, 15, 15]
		}],
		labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
		xaxis: {
			crosshairs: {
				width: 1
			}
		},
		tooltip: {
			fixed: {
				enabled: false
			},
			x: {
				show: false
			},
			y: {
				title: {
					formatter: function formatter(seriesName) {
						return '';
					}
				}
			},
			marker: {
				show: false
			}
		}
	};
	new ApexCharts(document.querySelector("#chart13"), options6).render();
	var options6 = {
		chart: {
			type: 'bar',
			width: '20%',
			height: 30,
			sparkline: {
				enabled: true
			}
		},
		plotOptions: {
			bar: {
				columnWidth: '40%'
			}
		},
		colors: ['#c43235'],
		series: [{
			data: [-25, 15, -35, 35, 55, -75, 100, 75, 55, -35, -25, -15, 15]
		}],
		labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
		xaxis: {
			crosshairs: {
				width: 1
			}
		},
		tooltip: {
			fixed: {
				enabled: false
			},
			x: {
				show: false
			},
			y: {
				title: {
					formatter: function formatter(seriesName) {
						return '';
					}
				}
			},
			marker: {
				show: false
			}
		}
	};
	new ApexCharts(document.querySelector("#widgetChart14"), options6).render();
	var options6 = {
		chart: {
			type: 'bar',
			width: '20%',
			height: 30,
			sparkline: {
				enabled: true
			}
		},
		plotOptions: {
			bar: {
				columnWidth: '40%'
			}
		},
		colors: ['#c43235'],
		series: [{
			data: [25, 15, -35, -35, -55, -75, -100, -75, -55, -35, -25, -15, -15]
		}],
		labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
		xaxis: {
			crosshairs: {
				width: 1
			}
		},
		tooltip: {
			fixed: {
				enabled: false
			},
			x: {
				show: false
			},
			y: {
				title: {
					formatter: function formatter(seriesName) {
						return '';
					}
				}
			},
			marker: {
				show: false
			}
		}
	};
	new ApexCharts(document.querySelector("#widgetChart15"), options6).render();
	var options6 = {
		chart: {
			type: 'bar',
			width: '20%',
			height: 30,
			sparkline: {
				enabled: true
			}
		},
		plotOptions: {
			bar: {
				columnWidth: '40%'
			}
		},
		colors: ['#c43235'],
		series: [{
			data: [25, 15, 35, 35, 55, 75, 90, 75, 55, 35, 25, 15, 15]
		}],
		labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
		xaxis: {
			crosshairs: {
				width: 1
			}
		},
		tooltip: {
			fixed: {
				enabled: false
			},
			x: {
				show: false
			},
			y: {
				title: {
					formatter: function formatter(seriesName) {
						return '';
					}
				}
			},
			marker: {
				show: false
			}
		}
	};
	new ApexCharts(document.querySelector("#widgetChart16"), options6).render();
	var options6 = {
		chart: {
			type: 'bar',
			width: '20%',
			height: 30,
			sparkline: {
				enabled: true
			}
		},
		plotOptions: {
			bar: {
				columnWidth: '40%'
			}
		},
		colors: ['#c43235'],
		series: [{
			data: [95, 75, 35, 35, 55, 25, 30, 75, 55, 55, 25, 65, 80]
		}],
		labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
		xaxis: {
			crosshairs: {
				width: 1
			}
		},
		tooltip: {
			fixed: {
				enabled: false
			},
			x: {
				show: false
			},
			y: {
				title: {
					formatter: function formatter(seriesName) {
						return '';
					}
				}
			},
			marker: {
				show: false
			}
		}
	};
	new ApexCharts(document.querySelector("#widgetChart17"), options6).render();
	var options6 = {
		chart: {
			type: 'bar',
			width: '20%',
			height: 30,
			sparkline: {
				enabled: true
			}
		},
		plotOptions: {
			bar: {
				columnWidth: '40%'
			}
		},
		colors: ['#c43235'],
		series: [{
			data: [25, 15, 35, 35, 55, 75, 90, 75, 55, 35, 25, 15, 15]
		}],
		labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
		xaxis: {
			crosshairs: {
				width: 1
			}
		},
		tooltip: {
			fixed: {
				enabled: false
			},
			x: {
				show: false
			},
			y: {
				title: {
					formatter: function formatter(seriesName) {
						return '';
					}
				}
			},
			marker: {
				show: false
			}
		}
	};
	new ApexCharts(document.querySelector("#widgetChart18"), options6).render();
	var options6 = {
		chart: {
			type: 'bar',
			width: '20%',
			height: 30,
			sparkline: {
				enabled: true
			}
		},
		plotOptions: {
			bar: {
				columnWidth: '40%'
			}
		},
		colors: ['#c43235'],
		series: [{
			data: [25, 15, -35, -35, -55, -75, -100, -75, -55, -35, -25, -15, -15]
		}],
		labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
		xaxis: {
			crosshairs: {
				width: 1
			}
		},
		tooltip: {
			fixed: {
				enabled: false
			},
			x: {
				show: false
			},
			y: {
				title: {
					formatter: function formatter(seriesName) {
						return '';
					}
				}
			},
			marker: {
				show: false
			}
		}
	};
	new ApexCharts(document.querySelector("#widgetChart19"), options6).render();
	var options1 = {
		chart: {
			type: 'line',
			width: '20%',
			height: 20,
			sparkline: {
				enabled: true
			}
		},
		series: [{
			data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54]
		}],
		tooltip: {
			fixed: {
				enabled: false
			},
			x: {
				show: false
			},
			y: {
				title: {
					formatter: function formatter(seriesName) {
						return '';
					}
				}
			},
			marker: {
				show: false
			}
		}
	};
	new ApexCharts(document.querySelector("#chart1"), options1).render();
}