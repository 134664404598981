"use strict";
$(document).ready(function() {
	switch (view()) {
		case "appointment.list":
		case "patient.profile":
			break;
		default:
			return;
	}
	var $table = $("#appointments");
	var $tbody = $table.find("tbody");
	var $info = $tbody.find("[data-appointment-description-priority]").filter(function() {
		return (parseInt($(this).attr("data-appointment-description-priority")) || 0) < 3;
	});
	$("[data-toggle-info]").on("click", function(event) {
		event.preventDefault();
		var target = $(this).closest("tr").next().find(".content")[0];
		$info.not(target).slideUp(0);
		$(target).slideToggle(0);
	});
	$("[data-appointment-priority]").on("click", function(event) {
		event.preventDefault();
		var priority = parseInt($(this).attr("data-appointment-priority"));
		if (isNaN(priority)) return;
		var $row = $(this).closest("[data-appointment-id]");
		var id = parseInt($row.attr("data-appointment-id"));
		var $button = $row.find("button");
		if (isNaN(id)) return;
		$.ajax({
			url: "/appointment/".concat(id, "/priority"),
			method: 'PUT',
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			},
			data: {
				priority: priority
			},
			dataType: "json"
		}).then(function(json) {
			var colors = ["btn-white", "btn-light", "btn-dark", "btn-secondary", "btn-primary", "btn-warning", "btn-danger"];
			colors.splice(colors.indexOf("btn-" + json.color), 1);
			$button.addClass("btn-" + json.color).text(json.label);
			$button[0].classList.forEach(className => {
				if (colors.includes(className)) {
					$button[0].classList.remove(className);
				}
			});
		}, function() {});
	});
	$("[data-appointment-tag]").on("change", function(event) {
		var tag = $(this).val();
		if (tag == null) return;
		var $row = $(this).closest("[data-appointment-id]");
		var id = parseInt($row.attr("data-appointment-id"));
		$.ajax({
			url: "/appointment/".concat(id, "/tag"),
			method: 'PUT',
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			},
			data: {
				tag: tag
			},
			dataType: "json"
		}).then(function(json) {}, function() {});
	});
	$("[data-appointment-block]").on("change", function(event) {
		var blocked = $(this).prop("checked");
		if (blocked == null) return;
		var $row = $(this).closest("[data-appointment-id]");
		var id = parseInt($row.attr("data-appointment-id"));
		if (isNaN(id)) return;
		$.ajax({
			url: "/appointment/".concat(id, "/block"),
			method: 'PUT',
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			},
			data: {
				block: blocked ? 1 : 0
			},
			dataType: "json"
		}).then(function(json) {}, function() {});
	});
	$("[data-appointment-details]").on("input", function(event) {
		if (window.appointment_details_interval != null) {
			clearInterval(window.appointment_details_interval);
			window.appointment_details_interval = null;
		}
		window.appointment_details_interval = setTimeout(function() {
			var details = $(this).val();
			if (details === null) return;
			var $row = $(this).closest("[data-appointment-id]");
			var id = parseInt($row.attr("data-appointment-id"));
			$.ajax({
				url: "/appointment/".concat(id, "/details"),
				method: 'PUT',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
				},
				data: {
					details: details
				},
				dataType: "json"
			}).then(function(json) {
				toastr.success("Η εγγραφή ενημερώθηκε με επιτυχία!", "Ενημερώθηκε!");
			}, function() {});
		}.bind(this), 1000);
	});
	$("[data-appointment-delete]").on("click", function(event) {
		event.preventDefault();
		var $row = $(this).closest("[data-appointment-id]");
		var id = parseInt($row.attr("data-appointment-id"));
		if (isNaN(id)) return;
		swal({
			type: 'error',
			title: 'Επιβεβαίωση διαγραφής',
			text: 'Θελέτε να διαγράψετε αυτή την επίσκεψη;',
			buttonsStyling: false,
			confirmButtonClass: 'btn btn-lg btn-danger mb-2 mr-3',
			cancelButtonClass: 'btn btn-lg btn-outline-secondary mb-2 mr-3 border-0',
			showCancelButton: true,
			confirmButtonText: 'Διαγραφή',
			cancelButtonText: 'Άκυρο'
		}).then(result => {
			$.ajax({
				url: "/appointment/".concat(id),
				method: 'DELETE',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
				},
				dataType: "json"
			}).then(function(json) {
				location.reload();
			}, function() {});
		}, result => {});
	});
	$("[data-appointment-datetime]").on("blur", function(event) {
		if (this.value == this.defaultValue) {
			return;
		}
		var datetime = $(this).val();
		if (datetime == null) return;
		var $row = $(this).closest("[data-appointment-id]");
		var id = parseInt($row.attr("data-appointment-id"));
		if (isNaN(id)) return;
		$.ajax({
			url: "/appointment/".concat(id, "/date"),
			method: 'PUT',
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			},
			data: {
				date: datetime
			},
			dataType: "json"
		}).then(function(json) {
			location.reload();
		}, function() {});
	});
	$("[data-appointment-action]").on("change", function(event) {
		var action = $(this).val();
		if (action == null) return;
		var $row = $(this).closest("[data-appointment-id]");
		var id = parseInt($row.attr("data-appointment-id"));
		if (isNaN(id)) return;
		$.ajax({
			url: "/appointment/".concat(id, "/action"),
			method: 'PUT',
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			},
			data: {
				action: action
			},
			dataType: "json"
		}).then(function(json) {}, function() {});
	});
});