"use strict";
$(document).ready(function() {
	"use strict";
	switch (layout()) {
		case "compact":
			break;
		default:
			return;
	}
	if ($(".layout-sidebar-compact").length === 0) {
		return;
	}
	var $appAdminWrap = $(".app-admin-wrap.layout-sidebar-compact");
	var $sidebarToggle = $appAdminWrap.find(".menu-toggle");
	var $sidebarLeft = $appAdminWrap.find(".sidebar-left");
	var $sidebarLeftSecondary = $appAdminWrap.find(".sidebar-left-secondary");
	var $sideNavItem = $appAdminWrap.find(".nav-item");

	function navItemToggleActive($activeItem) {
		var $navItem = $(".nav-item");
		$navItem.removeClass("active");
		$activeItem.addClass("active");
	}

	function initLayout() {
		$sideNavItem.each(function(index) {
			let $item = $(this);
			if ($item.hasClass("active")) {
				let dataItem = $item.data("item");
				$sidebarLeftSecondary.find("[data-parent=\"".concat(dataItem, "\"]")).show();
			}
		});
		if (gullUtils.isMobile()) {
			$appAdminWrap.removeClass("sidenav-open");
		}
	}
	$(window).on("resize", function(event) {
		if (gullUtils.isMobile()) {
			$appAdminWrap.removeClass("sidenav-open");
		}
	});
	initLayout();
	$sidebarLeft.find(".nav-item").on("mouseenter", function(event) {
		let $navItem = $(event.currentTarget);
		let dataItem = $navItem.data("item");
		if (dataItem) {
			navItemToggleActive($navItem);
			$sidebarLeftSecondary.find(".submenu-area").hide();
			$sidebarLeftSecondary.find("[data-parent=\"".concat(dataItem, "\"]")).show();
		}
	});
	$sidebarLeft.find(".nav-item").on("click", function(e) {
		let $navItem = $(event.currentTarget);
		let dataItem = $navItem.data("item");
		if (dataItem) {
			e.preventDefault();
		}
	});
	$sidebarToggle.on("click", function(event) {
		$appAdminWrap.toggleClass("sidenav-open");
	});
	$('.sidebar-close').on('click', function(e) {
		$appAdminWrap.removeClass("sidenav-open");
	});
});