"use strict";
jQuery.Deferred.exceptionHook = function(e) {
	if (e == null) return;
	throw e;
};
$(document).ready(function() {
	"use strict";
	var $searchIcon = $("#search-toggle");
	var $searchCloseBtn = $(".search-close");
	window.gullUtils = {
		isMobile: function isMobile() {
			return window && window.matchMedia("(max-width: 767px)").matches;
		},
		changeCssLink: function changeCssLink(storageKey, fileUrl) {
			localStorage.setItem(storageKey, fileUrl);
			location.reload();
		}
	};
	var $searchUI = $(".search-ui");
	$searchIcon.on("click", function() {
		$searchUI.addClass("open");
		$(".search-input").focus();
	});
	$searchCloseBtn.on("click", function() {
		$searchUI.removeClass("open");
	});
	var $dropdown = $(".dropdown-sidemenu");
	var $subMenu = $(".submenu");
	$dropdown.find("> a").on("click", function(e) {
		e.preventDefault();
		e.stopPropagation();
		var $parent = $(this).parent(".dropdown-sidemenu");
		$dropdown.not($parent).removeClass("open");
		$(this).parent(".dropdown-sidemenu").toggleClass("open");
	});
	$(".perfect-scrollbar, [data-perfect-scrollbar]").each(function(index) {
		var $el = $(this);
		var ps = new PerfectScrollbar(this, {
			suppressScrollX: $el.data("suppress-scroll-x"),
			suppressScrollY: $el.data("suppress-scroll-y")
		});
	});

	function cancelFullScreen(el) {
		var requestMethod = el.cancelFullScreen || el.webkitCancelFullScreen || el.mozCancelFullScreen || el.exitFullscreen;
		if (requestMethod) {
			requestMethod.call(el);
		} else if (typeof window.ActiveXObject !== "undefined") {
			var wscript = new ActiveXObject("WScript.Shell");
			if (wscript !== null) {
				wscript.SendKeys("{F11}");
			}
		}
	}

	function requestFullScreen(el) {
		var requestMethod = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullscreen;
		if (requestMethod) {
			requestMethod.call(el);
		} else if (typeof window.ActiveXObject !== "undefined") {
			var wscript = new ActiveXObject("WScript.Shell");
			if (wscript !== null) {
				wscript.SendKeys("{F11}");
			}
		}
		return false;
	}

	function toggleFullscreen() {
		var elem = document.body;
		var isInFullScreen = document.fullScreenElement && document.fullScreenElement !== null || document.mozFullScreen || document.webkitIsFullScreen;
		if (isInFullScreen) {
			cancelFullScreen(document);
		} else {
			requestFullScreen(elem);
		}
		return false;
	}
	$("[data-fullscreen]").on("click", toggleFullscreen);
});
$(window).on("load", function() {
	jQuery("#loader").fadeOut();
	jQuery("#preloader").delay(500).fadeOut("slow");
});