"use strict";
if (view() === "apps.task-manager-list") {
	let _card_body = document.getElementById('card-body');
	let _card_footer = document.getElementById('card-footer');
	let _arrow_down = document.getElementById('arrow-down');
	let closeWindow = document.getElementById('close-window');
	let _card = document.getElementById('card');
	let _reload = document.getElementById('reload');
	_arrow_down.addEventListener('click', minimizeButton);

	function minimizeButton(e) {
		_card_body.classList.toggle('active');
		e.preventDefault();
	}
	_reload.addEventListener('click', _reloadFunction);

	function _reloadFunction(e) {
		_card_body.reload();
		e.preventDefault();
	}
	closeWindow.addEventListener('click', removeWindow);

	function removeWindow(e) {
		_card.classList.add('active');
		e.preventDefault();
	}
	let filterInput = document.getElementById('filterInput');
}