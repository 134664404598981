"use strict";
$(document).ready(function() {
	switch (view()) {
		case "apps.contacts.lists":
		case "uiKits.tooltip":
		case "widgets.list":
			break;
		default:
			if (layout() === "vertical") {
				break;
			}
			return;
	}
	$(function() {
		$('[data-toggle="tooltip"]').tooltip();
	});
	if ($(".layout-sidebar-vertical").length === 0) {
		return;
	}
	$('#manual').on('click', function() {
		$(this).tooltip('toggle');
	});
});